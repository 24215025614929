import React, { FC } from 'react'
import desktopBackgroundImage from '../../images/services/banner-placements-desktop.jpg'
import mobileBackgroundImage from '../../images/services/banner-placements-mobile.jpg'
import { Colors } from '../../colors'
import ServicePageLayout from '../../components/layouts/service-page-layout'
import { AvailableServices } from '../../available-services'
import { ServiceDetail } from '../../components/service-detail/service-detail'

export const Placements: FC = () => (
    <ServicePageLayout
        primaryColor={Colors.turquoise}
        title="Placements et gestion financière"
        desktopBanner={desktopBackgroundImage}
        mobileBanner={mobileBackgroundImage}
        bannerText="Placements et gestion financière. Bâtissez un avenir prospère dès maintenant."
        serviceBrief={`La vie est toujours remplie de surprises! Il vous est impossible de prédire votre avenir, mais il est essentiel de vous assurer d’être préparé à toute éventualité pour ne pas manquer le bateau. Planifiez et atteignez vos objectifs grâce à des solutions et à des conseils qui englobent ce que vous êtes aujourd’hui et ce que vous souhaitez devenir demain.`}
        serviceToHide={AvailableServices.placements}
    >
        <>
            <ServiceDetail
                color={Colors.turquoise}
                title="REER et autres régimes"
                details="Pour bien penser et élaborer le projet de retraite auquel vous rêvez depuis longtemps."
            />
            <ServiceDetail
                color={Colors.skyBlue}
                title="Fonds de placement"
                details="Pour investir vos économies et faire fructifier votre argent au maximum."
            />
            <ServiceDetail
                color={Colors.yellow}
                title="FERR, FRV, CRI et rentes"
                details="Pour convertir vos REER en revenu de retraite."
            />
            <ServiceDetail
                color={Colors.purple}
                title="REEI"
                details={`Pour assurer la sécurité financière à long terme d'une personne handicapée.`}
            />
            <ServiceDetail
                color={Colors.skyBlue}
                title="REEE"
                details="Pour les études post-secondaires de vos enfants."
            />
            <ServiceDetail
                color={Colors.turquoise}
                title="REER et autres régimes"
                details={`Pour bien penser et élaborer le projet de retraite auquel vous rêvez depuis longtemps.`}
            />
        </>
    </ServicePageLayout>
)

export default Placements
